.yearOverviewResultsTitle {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
  
  .myTable {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    border: 1px solid black; 
    width: 90%;                      
    margin: 0 auto;         
    border-spacing: 0;
  }
  
  .myTable td, .myTable th {
    border: 1px solid #ddd; 
  }
  
  .myTable th {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  td {
    font-family: system-ui;
    font-size: 14px;
  }
  
  .myTable tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .myTable tr:hover {
    background-color: #ddd;
}
  
  .alignRight {
    text-align: right;
  }
  .boldText {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
  }
  
  .Loading {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    margin-top: 20px;
    font-weight: normal !important;
    font-weight: 12px;
  }

  .yipButtonStyle {  
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    font: inherit;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width: 100%
}

.sortButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.sortButtonSpan {
    font-family: 'Courier New', monospace;
}

.customSelect {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border: none;
  color: #000;
  background: transparent;
  outline: none;
  text-align: center;
  font-weight: bold;
  /* appearance: none; */
}
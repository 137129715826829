.net-budget-rate-container {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    width: 80%;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.net-budget-rate-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-row label {
    width: 40%;
    font-weight: bold;
}

.form-row input {
    width: 50%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.total-row input {
    font-weight: bold;
}

.form-row button {
    width: 100%;
    padding: 10px;
    background-color: #8aabf4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-row button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.short-input {
    max-width: 98.5%;
}
.vendorTable {
    width: 90%;
    margin: 20px auto;
    border-collapse: collapse;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.vendorTable th, .vendorTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.vendorTable th {
    background-color: rgb(205, 255, 255);
    font-weight: bold;
}

.vendorTable tr:hover {
    background-color: #ddd;
}

.MEPsearchBar {
    justify-content: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    max-width: min-content;
}

.MEPsearchBar input {
    padding: 8px;
    font-size: 16px;
    width: 300px;
}

.MEPsearchBar button {
    padding: 8px 16px;
    font-size: 16px;
    margin-left: 10px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 14px;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.noResults {
    text-align: center;
    font-size: 18px;
    color: darkgray;
    margin-top: 20px;
}

.vendorLink {
    color: black;
    text-decoration: underline;
}

.vendorLink:hover {
    text-decoration: none;
}

.MEPsearchBarContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 20px auto; 
    width: 90vw; 
    padding-top: 2em;
}


.createVendorButton {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: rgb(85, 190, 225);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.createVendorButton:hover {
    background-color: rgb(160, 210, 225);
}

.MEPTitleContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}
.MEPTitle {
    font-size: 2em;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
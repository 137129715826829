.vendorViewContainer {
    font-family: Arial, sans-serif;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.vendorDetail {
    width: 100%;
    max-width: 1200px;
}



.vendorDetailTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MEPcreateButton {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: rgb(85, 190, 225);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.MEPcreateButton:hover {
    background-color: rgb(160, 210, 225);
}

.vendorForm {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: white;
    width: 100%;
    padding-left: 0;
}

.formRow {
    display: contents;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formGroup label {
    margin-bottom: 5px;
}

.formGroup input,
.formGroup textarea,
.formGroup select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

textarea {
    resize: vertical;
    min-height: 100px;
}

.formRowBottom {
    grid-column: span 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderNFP {
    position: relative; 
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    background-color: rgb(85, 190, 225);
    color:  rgb(255, 255, 255);
    height: 6vh;
    font-family: 'Trebuchet MS', sans-serif;
    box-sizing: border-box;
    padding-left: 20px;
}


.logo {
    position: absolute; 
    left: 20px; 
}
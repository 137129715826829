.Login {
    /* background-color: rgb(160, 210, 225); */
    background-color: rgb(85, 190, 225);
    height: 100vh;
    display: flex;
    justify-content: space-between;
}


form {
    /* background-color: rgb(160, 210, 225); */
    background-color: rgb(85, 190, 225);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 3em;
    gap: 2em;
    top: 0;
    height: auto;
    max-height: none;
    width: 30vw;
}

.form-label{
    font-family: 'Trebuchet MS';
    font-weight: bold;
    font-size: 20px;
    color: white;

    display:grid;
    align-items: center;
}

.form-input {
    width: 80%;
    height: 1em;
    border: none;
    padding: 0.25em;
    border-radius: 4px;
    font-family: 'Trebuchet MS';
    font-size: 20px;
}

.form-submit {
    padding: 15px 30px;
    font-family: 'Trebuchet MS';
    font-weight: bold;
    font-size: 20px;
    color: white;
    border: 2px rgb(160, 210, 225) solid;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(85, 190, 225);
}

.form-submit:hover {
    background-color: rgb(160, 210, 225);
}


.error {
    color: red;
    font-family: 'Trebuchet MS';
    font-size: 20px;
    margin: 0;
}

.company-info {
    background-color:  rgb(160, 210, 225) ;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
    padding-right: 20px;
}

.company-info p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: Bold;
    color: white;
    font-size: 6em; /* adjust as needed */
    text-align: right;
}

.company-info-color {
    color: rgb(40, 75, 110);
}
.container {
    display: flex;
    justify-content: center;
    padding: 2em;
    gap: 2em;
}

.left-buttons, .middle-buttons, .right-buttons {
    display: flex;
    flex-direction: column;
    gap: 1em;    
}

.button {
    width: 15em; 
    height: 5em;
    padding: 1em;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #000;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background-color: #e0e0e0;
}

.delete-button {
    align-self: flex-start;
}
.new-project-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  input, select, button {
    padding: 8px;
    margin-top: 5px;
  }
  
.Header {
    position: relative; 
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    background-color: rgb(85, 190, 225);
    color:  rgb(255, 255, 255);
    height: 6vh;
    font-family: 'Trebuchet MS', sans-serif;
    box-sizing: border-box;
    padding-left: 20px;
}



.logo {
    position: absolute; 
    left: 20px; 
}


.logo img {
    max-width: 200px; 
    height: auto; 
}

.navigation {
    display: flex;
    justify-content: center;
    gap: 10px; 
}

.profile {
    position: absolute; 
    right: 20px; 
}

.dropdown {
    position: relative ;
    display: inline-block;
    text-align: left;
}

.dropbtn {
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 20px;
    cursor: default;
    background: none;
    color: inherit;
    text-align: left;
}

.homebtn {
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    background: none;
    color: inherit;
    text-align: left;
    text-decoration: none;
}

.homebtn:hover {
    color: rgb(160, 210, 225);
}

.dropdown-content {
    left: 0;
    position: absolute;
    top: 4vh; 
    background-color: rgb(85, 190, 225);
    min-width: 160px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s, visibility 0.5s;
    text-align: left;
}

.dropdown-content a {
    padding-left: 0;
    color: inherit;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    color: rgb(160, 210, 225);
}

.dropdown:hover .dropdown-content {
    visibility: visible;
    max-height: 500px; /* Adjust this value to fit your content */
}

.dropbtnU {
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    background: none;
    color: inherit;
    text-align: left;
}

.dropbtnU:hover{
    color: rgb(160, 210, 225);
}



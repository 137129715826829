.staff-list-container {
    margin: 50px auto;
    width: 80%;
    text-align: center;
}

.staff-list-table {
    width: 100%;
    border-collapse: collapse;
}

.staff-list-table th, .staff-list-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.staff-list-table th {
    background-color: #f2f2f2;
}

.status-leave {
    background-color: #F7B3B4;
}

.status-internship {
    background-color: #A8F5B3;
}

.status-current {
    background-color: #EFCC3B;
}

.remove-btn {
    background-color: aqua;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.remove-btn:hover {
    background-color: #FF69B4;
    color: white;
}

/* General Styles */
.proposal-detail {
    font-family: Arial, sans-serif;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 800px;
}

.proposal-detail.loading {
    box-shadow: none;
    text-align: center;
    font-size: 20px;
    color: #333;
}

/* Header */
.proposal-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

/* Form Styles */
.proposal-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-row {
    display: flex;
    flex-direction: column;
}

.form-row label {
    font-weight: bold;
    margin-bottom: 5px;
}

.form-row input[type="text"],
.form-row input[type="date"],
.form-row select,
.form-row textarea {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.form-row textarea {
    resize: vertical;
}

.form-row select {
    width: 100%;
    height: 40px;
}

.form-row button {
    align-self: center;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(85, 190, 225);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.form-row button:hover {
    background-color: rgb(160, 210, 225);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .proposal-detail {
        padding: 15px;
    }

    .form-row label {
        font-size: 14px;
    }

    .form-row input[type="text"],
    .form-row input[type="date"],
    .form-row select,
    .form-row textarea {
        font-size: 13px;
        padding: 8px;
    }

    .form-row button {
        width: 100%;
        font-size: 14px;
    }
}

.proposal-list-container {
    margin: 0.1em auto;
    width: 80%;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 2em;
}

.proposal-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin: 0 auto;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.proposal-table th, .proposal-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-family: system-ui;
    font-size: 14px;
}

.proposal-table th {
    background-color: rgb(205, 255, 255);
    color: black;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.proposal-table td a {
    color: black;
    text-decoration: underline;
}

.proposal-table td a:hover {
    text-decoration: none;
}

.proposal-table tr:nth-child(even) {
    background-color: #f2f2f2;
}


.proposal-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.proposal-list-title {
    font-size: 1em;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
}


.new-proposal-button {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: rgb(85, 190, 225);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.new-proposal-button:hover {
    background-color: rgb(160, 210, 225);
}

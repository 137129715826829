.timesheet-container {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0 auto;
    padding: 20px;
    max-width: 90%
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.week-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.week-selector label {
    margin-right: 10px;
    font-size: 18px;
}

.timesheet-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.timesheet-table th, .timesheet-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}

.timesheet-table th {
    background-color: rgb(205, 255, 255);;
}



.timesheet-table input[type="number"] {
    width: 60px;
    padding: 5px;
    text-align: center;
}

.timesheet-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-row-btn, .submit-btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.add-row-btn:hover, .submit-btn:hover {
    background-color: #0056b3;
}

.total-hours {
    font-size: 18px;
    font-weight: bold;
}

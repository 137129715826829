.project-detail {
    font-family: Arial, sans-serif;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.project-detail.loading {
    box-shadow: none;
}

.project-detail-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-detail-title{
    padding-left: 0.5em;
    padding-top: 0.5em;
    width: 95%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.Loading {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    margin-top: 20px;
    font-weight: normal !important;
    font-weight: 12px;
  }
.save-button-container {
    padding-right: 1em;
}

.save-button {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: rgb(85, 190, 225);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.save-button:hover {
    background-color: rgb(160, 210, 225);
}

.title-input-edit {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: light;
    padding-right: 0.5em;
    width: 95%;
    font-size: 1em;
    font-weight: bold;
}

.icon-edit {
    margin-left: 2em;
    cursor: pointer;
    font-size: 0.5em;
    position: relative;
    top: -1em;
}

.project-container {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.project-information {
    background-color: #f9f9f9;
    padding:1em;
    border: 2px solid #ccc;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 60%;
}

.form-row-1 {
    display: flex;
    justify-content: space-between;
}

.form-row-1 label {
    display: flex;
    flex-direction: column;
}

/* input[type="text"], input[type="date"], select, textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
} */

input[type="text"], input[type="date"], textarea, select {
    color: black;
}

textarea {
    min-height: 10em;
    width: 100%;
}

.project-number {
    width: 35%;
}

.proposal-number {
    width: 35%;
}

.status{
    width: 20%;
}

.form-row-2 {
    display: flex;
    margin-top: 1em;
    justify-content: space-between;
}

.form-row-2 label {
    display: flex;
    flex-direction: column;
}

.date{
    width: 15%;

}

.address{
    width: 50%;
}

.city{
    width: 15%;
}


.state{
    width: 10%;
}


.form-row-3 {
    display: flex;
    margin-top: 1em;
    justify-content: space-between;
}

.form-row-3 label {
    display: flex;
    flex-direction: column;
}

.nameTag{
    width: 25%;
}


.form-row-4 {
    display: flex;
    margin-top: 1em;
    justify-content: space-between;
}

.form-row-4 label {
    display: flex;
    flex-direction: column;
}


.form-row-5 {
    display: flex;
    margin-top: 1em;
    justify-content: space-between;
}

.form-row-5 label {
    display: flex;
    flex-direction: column;
}

.invoicing {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    width: 100%;
}

.invoicing label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.invoicing textarea {
    box-sizing: border-box;
    min-height: 15em;
}

.scope {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    width: 100%;
}

.scope label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scope textarea {
    box-sizing: border-box;
    min-height: 20em;
}

.notes-section {
    display: flex;
    flex-direction: column;
    width: 35%;
    background-color: #f9f9f9;
    padding:1em;
    border: 2px solid #ccc;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.notes-container-top {
}


.notes-container {
    display: flex;
    justify-content: space-between;;
    flex-grow: 1;
    gap: 0.5em;
    height: 100vh;
}



.notes-tabs {
    flex-direction: column;
    max-height: 80%;
    overflow-y: auto;
}

.note-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1em;
}

.note-tab {
    cursor: pointer;
    background-color: #f0f0f0;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    outline: none;
}

.note-tab:hover {
    background-color: #e0e0e0; 
}

.note-tab.selected {
    background-color: #bbb; 

}

.note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.note-header > div {
    flex-grow: 1;
}

.note-content textarea {
    margin-top: 0.5em;
    width: calc(100%); 
    min-height: 80%;
    box-sizing: border-box; 
}


.note-actions {
    display: flex;
}

.add-note-button{
    height: 4em;
    margin-bottom: 1em;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  

.modal {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 40%;
}

.modal textarea {
    width: 100%;
    min-height: 80%;
    box-sizing: border-box;
}

.modal-buttons{
    display: flex;
    justify-content: space-between;
    
}

.budgetTable {
    text-align: center;
}

.budgetTable table {
    table-layout: fixed; 
    width: 100%;
}

.budgetTable td {
    word-wrap: break-word; 
}

.budgetTable label, .budgetTable input {
    max-width: 100%; 
    box-sizing: border-box; 
}

.budgetTable input {
    width: 100%; 
}
.field-container {
    display: flex;
    flex-direction: column;
}

.field-container label {
    min-height: 40px; 
}

.field-container .flex-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
}

.field-container select, .field-container input {
    width: 100%; 
}

.discipline-section {
    width: 100%; 
    margin-top: 1em;
}

.full-width-table {
    width: 100%;
    margin-top: 1em;
    font-size: 12px;
}

.full-width-table-2 {
    width: 100%;
    font-size: 12px;
}

.table-header th{
}

.th {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 10px !important;
    font-weight: normal !important;
}
.mep-purchase-order-container {
    font-family: Arial, sans-serif;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 900px;
}

.mep-purchase-order-container h2, .mep-purchase-order-container h3 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.form-row label {
    font-weight: bold;
    padding-top: 1em;
}

.short-input {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.note-input {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 60px;
    resize: vertical;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #55bee1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #a0d2e1;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.orders-table th, .orders-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.orders-table th {
    background-color: #f5f5f5;
}

.item-row {
    margin-bottom: 20px;
}

.item-row .form-row {
    margin-bottom: 10px;
}

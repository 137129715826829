.contactTable {
    width: 90%;
    margin: 20px auto;
    border-collapse: collapse;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
}

.contactTable th, .contactTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

.contactTable th {
    background-color: rgb(205, 255, 255);
    font-weight: bold;
}

.contactTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.contactTable tr:hover {
    background-color: #ddd;
}

.ContactSearchBarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    width: 90%;
    padding-top: 1em;
}

.ContactSearchBar input {
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
}

.ContactTitleContainer {
    position: static;
    transform: translateX(-25%);
    text-align: center;
}

.ContactTitle {
    font-size: 2em;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.noResults {
    text-align: center;
    font-size: 18px;
    color: darkgray;
    margin-top: 20px;
}

.Loading {
    text-align: center;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
}

.contactLink {
    color: black;
    text-decoration: underline;
}

.contactLink:hover {
    text-decoration: none;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 14px;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.createContactButton {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: rgb(85, 190, 225);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.createContactButton:hover {
    background-color: rgb(160, 210, 225);
}
.sidebar {
    position: fixed;
    right: -100%;
    width: 15vw;
    height: 100vh;
    top: 0;
    background-color: #8d87e3;
    transition: right 0.3s ease-in-out;

    color: white;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 20px;
    text-decoration: none;
    
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: space-between;
    z-index: 2;
}

.sidebar.open {
    right: 0;
}

body.sidebar-open {
    filter: blur(5px); 
}

.sidebar-top button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.sidebar-top button svg {
    fill: white; 
    width: 1em; 
    height: 1em; 
}

.sidebar-top button:hover svg {
    fill: #bab7e0; 
}

.sidebar-USER{
    display: flex; 
    font-weight: bold;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    height: 25%; 
}

.sidebar-middle{
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: flex-start;
    gap: 20px;
    height: 50%; 
}

.sidebar-bottom{
    display: flex; 
    font-weight: bold;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    height: 20%; 
}

.sidebar-bottom button {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 20px;
}

.sidebar button, .sidebar a {
    color: white; 
    padding: 12px 16px; 
    text-decoration: none; 
    background: none; 
    border: none; 
    cursor: pointer; 
    display: block; 
    text-align: left; 
}

.sidebar button:hover, .sidebar a:hover {
    color: #bab7e0; 
}


@keyframes slideIn {
    0% {
        right: -100%;
    }
    100% {
        right: 0;
    }
}


@keyframes slideOut {
    0% {
        right: 0;
    }
    100% {
        right: -100%;
    }
}

.sidebar.open {
    transition: right 0.3s ease-in-out;
}

.sidebar {
    transition: right 0.3s ease-in-out;
}

.sidebar::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    /*backdrop-filter: blur(px); */
    z-index: -1; 
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none
}

.sidebar.open::before {
    opacity: 1;
}

.position-list-container {
    font-family: Arial, sans-serif;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 900px;
}

.position-list-container h2, .position-list-container h3 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.form-row {
    display: flex;
    justify-content: space-between;
    align-items: left;
    margin-bottom: 15px;
}

.form-row label {
    width: 20%;
    font-weight: bold;
}

.short-input {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
}

.note-input {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 60px;
    resize: vertical;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #55bee1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #a0d2e1;
}

.discipline-group {
    margin-top: 30px;
}

.team-members-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.team-members-table th, .team-members-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.team-members-table th {
    background-color: #f5f5f5;
}

.team-members-table td button {
    padding: 5px 10px;
    font-size: 14px;
    color: black;
    background-color: #ff8080;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.team-members-table td button:hover {
    background-color: #ff3333;
}

.new-member-form {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.new-member-form .form-row {
    margin-bottom: 15px;
}

.new-member-form .form-row label {
    width: 25%;
    font-weight: bold;
}

.new-member-form .form-row .short-input,
.new-member-form .form-row .note-input {
    width: 97%;
    text-align: left;
}

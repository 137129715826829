html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}
.Homepage {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif

}

.Loading h1{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  margin-top: 20px;
  font-weight: normal !important;
  font-weight: 12px;
}

.Homepage h1 {
    font-weight: bold;
    font-size: 2em;
}

table {
    width: 85%;
    border-collapse: collapse;
}

th{
  background-color: rgb(205, 255, 255);
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}


tr:nth-child(even) {
  background-color: #f2f2f2;
}

.searchBar {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  justify-items: center;
  margin-top: 20px;
  background-color: white;
  font-size: 18px;
}

.searchBarInput {
  align-items: center;
  border-radius: 3px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  border: black;
  border-style: solid;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  width: 50vw;
}

.searchBarSubmit {
  font-family: 'Trebuchet MS';
  font-size: 20px;
  color: white;
  /* border: 2px rgb(85, 190, 225) solid; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(85, 190, 225);
}

.searchBarSubmit:hover {
  background-color: rgb(160, 210, 225);
}

.horizontalBar {
  height: 2px;
  background-color: #ddd; 
  width: 100%; 
  margin-top: 1em;
}

.userDetails {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-align: center;
  font-size: 1em;
  margin-bottom: 1em;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.leaveTable{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.holidayTable {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.yearButton {
  color: #000;  
}

.yearButton:hover {
  text-decoration: none; 
}
.proposal-settings {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.proposal-form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.proposal-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
}

.proposal-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.proposal-form button:hover {
    background-color: #0056b3;
}

.proposal-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40px;
}

.proposal-table th,
.proposal-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.proposal-table th {
    background-color: #f2f2f2;
}

.proposal-table td button {
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.proposal-table td button:hover {
    background-color: #c82333;
}
